@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.non-selectable {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

:root {
    --primary-bg-color: #454546;
    --secundary-bg-color: #2f2f2f;
    --tertiary-bg-color: #d7d6d6;
    --fourth-bg-color: #252525;
    --fifth-bg-color: #191919;

    --primary-button-color: #2882b7;
    --secundary-button-color: #ffcc00;
    --tertiary-button-color: #f98229;

    --primary-knob-color: #fff;
    --secundary-knob-color: #7bb22b;
    --tertiary-knob-color: #33a0e1;

    --alert-color: #ff0000;
}

body {
    background-color: var(--secundary-bg-color);
    font-family: av0;
    color: var(--tertiary-bg-color);
}

.content {
    display: flex;
}

h2.title {
    color: var(--secundary-button-color);
}

.form h2 {
    font-size: 1.5rem;
}

.form-body {
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    margin-top: 3rem;
}

.form-item {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    width: 100%;
}

.form-label {
    width: 30%;
}

.form-input {
    width: 100%;
}

select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 1px solid var(--fourth-bg-color);
    color: var(--tertiary-bg-color);
    width: inherit;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: var(--fourth-bg-color);
}

select:focus + .focus {
    background-color: var(--primary-bg-color);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 1px solid var(--fourth-bg-color);
    color: var(--tertiary-bg-color);
    width: inherit;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: var(--fourth-bg-color);
    margin-right: 4rem;
}

table.table {
    width: 100%;
}

.table th,
.table td {
    text-align: left;
    padding: 0.25em;
}

.table tr {
    border-bottom: 1px solid #ddd;
}

.form-select-icon {
    width: 2rem;
    background-color: var(--fourth-bg-color);
    border-radius: 0.2rem 0 0.2rem 0;
    text-align: center;
    margin: auto;
    position: relative;
    right: 2rem;
}

.header {
    background-color: var(--fourth-bg-color);
    padding: 0.7rem;
    display: flex;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-background {
    background-color: var(--secundary-button-color);
    font-weight: bolder;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.logo-font {
    color: var(--secundary-bg-color);
    font-weight: bolder;
    font-size: 1.2rem;
    font-family: av1;
    padding-top: 0.1rem;
}

.logo-label {
    background-color: var(--fourth-bg-color);
    color: var(--secundary-button-color);
    margin-left: 0.4rem;
}

.logo-sign-out {
    font-size: 1.5rem;
    cursor: pointer;
}
.nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.nav-item {
    color: var(--primary-knob-color);
    padding-right: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.nav-item-active {
    color: var(--secundary-button-color);
    padding-right: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.nav-item:hover {
    color: var(--secundary-button-color);
    cursor: pointer;
}

.userImage {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.nav-left {
    display: flex;
    align-items: center;
}

.progression-history {
	margin: 2rem;
}

.progression-history-list {
	display: block;
}

.progression-history-item-chords {
	display: flex;
	margin: 1rem;
}

.progression-history-item-chord {
	display: flex;
	 
	border-right: .1rem solid var(--secundary-knob-color);
	padding-right: 1rem;
	padding-left: 1rem;
}
.home{
    margin: 3rem 2rem 2rem 2rem;
}
@font-face {
	font-family: "av0";
	src: url(/static/media/av0.1cbd45ae.woff) format("woff2");
}
@font-face {
	font-family: "av1";
	src: url(/static/media/av1.fbceb65a.woff) format("woff2");
}

.login {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.login-header {
	background-color: var(--fifth-bg-color);
	padding: 0.3rem;
	display: flex;
	height: 8rem;
	justify-content: center;
	width: 100%;
}

.login-header-logo {
	align-items: center;
	display: flex;
}

.login-header-logo-background {
	background-color: var(--secundary-button-color);
	font-weight: bolder;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}

.login-header-logo-font {
	color: var(--secundary-bg-color);
	font-weight: bolder;
	font-size: 2rem;
	font-family: av1;
	padding-top: 0.5rem;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}

.login-header-logo-label {
	background-color: var(--fifth-bg-color);
	color: var(--secundary-button-color);
	font-size: 1.6rem;
	font-family: av0;
	margin-left: 0.4rem;
	display: flex;
}

.login-header-logo-label > div{
	font-size: .6rem;
}

.login-form {
	background-color: var(--secundary-bg-color);
	border-radius: 0.5rem;
	border: 0.1px var(--tertiary-knob-color) solid;
	margin-top: 4rem;
	padding: 6rem;
	width: auto;
}

.login-service {
	padding: 1rem;
	display: flex;
	border: 0.1px var(--secundary-bg-color) solid;
}

.login-service:hover {
	border-radius: 0.5rem;
	border: 0.1px var(--tertiary-knob-color) solid;
	color: var(--tertiary-knob-color);
	cursor: pointer;
}

.login-service:hover div.login-service-logo {
	background-color: var(--tertiary-knob-color);
}

.login-service-logo {
	background-color: var(--primary-bg-color);
	color: var(--primary-knob-color);
	font-size: 1.5rem;
	padding: 1rem;
	text-align: center;
	width: 3.5rem;
}

.login-service-label {
	color: var(--primary-knob-color);
	font-size: 1rem;
	padding: 1rem;
}

.settings {
	display: flex;
    width: 100%;
}

.settings-column {    
    padding: 2rem;
    width: 50%;
}







.lessons {
	margin: 2rem;
}

.profile{
     
    
}

.learning-menu {
	color: var(--primary-knob-color);
	display: flex;
	margin: 2rem;
}

.learning-menu-item {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	background-color: var(--primary-bg-color);
}

.learning-menu-item-active {
	background-color: var(--tertiary-knob-color);
}

.learning-menu-item:hover {
	cursor: pointer;
}

#svg circle {
   
    transition: stroke-dashoffset 1s linear;
    stroke: #666;
    stroke-width: 1em;
    
  }
  #svg #bar {
    stroke: #FF9F1E; 
  }
  #cont {
    display: block;
    height: 200px;
    width: 200px;
    margin: 2em auto;
    box-shadow: 0 0 1em black;
    border-radius: 100%;
    position: relative;
  }
  #cont:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    box-shadow: inset 0 0 1em black;
    content: attr(data-pct)"%";
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 2em;
    text-shadow: 0 0 0.5em black;
  }
  
.courses {
	color: var(--primary-knob-color);
	display: flex;
	flex-wrap: wrap;
}

.courses-item {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	flex-grow: 2;
	display: flex;
	min-width: 300px;
	margin-bottom: 1.5rem;
}

.courses-item-fcover {
	width: 40%;
	height: 15rem;
	background-repeat: no-repeat;
	background-size: cover;
}

.courses-item-bcover {
	width: 60%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 1rem;
}

.courses-item-title {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.courses-item-author {
	font-size: 1.3rem;
	margin-bottom: 1rem;
}

.courses-item-description {
	font-size: 1rem;
}

.form-button {
	padding: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	background-color: var(--tertiary-knob-color);
	margin-left: 1rem;
	margin-top: 12rem;
}

.form-button:hover {
	cursor: pointer;
}


.circle {
    width: 400px;
    height: 400px;
    margin: 1.5rem;
}

#svgcircle tspan {
    font-size: 2rem;
    font-weight: bolder;
    color: var(--primary-knob-color);
}
/*
path {
	fill: var(--tertiary-bg-color);
}
path:hover {
	fill: var(--tertiary-knob-color);
	cursor: pointer; 
}
*/

.intervals {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    width: 400px;
}

.intervals-id {
    display: flex;
    flex-wrap: nowrap;
}

.intervals-notes {
}

.intervals-item {
    box-sizing: border-box;
    flex-grow: 1;
    width: 3rem;
    padding: 0.6em;
    overflow: hidden;
    list-style: none;
    background-color: var(--secundary-bg-color);
    margin: 0.1rem;
}

.intervals-item:hover {
    background-color: var(--secundary-bg-color);
    color: var(--primary-bg-color);
    cursor: pointer;
}

.notes {
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
	vertical-align: middle;
}

.note {
	background-color: var(--primary-bg-color);
    color: var(--tertiary-bg-color);
	border-radius: .5rem;
    height: 2.5rem;
    margin: 0.1rem;
    width: 2.5rem;
}

.active{
    background-color: var(--primary-button-color);
    color: var(--primary-knob-color);
}

.note:hover{
    background-color: var(--primary-button-color);
    color: var(--primary-knob-color);
    cursor: pointer;
}

.note > p {
    font-size: .8rem;
    font-weight: bolder;
	text-align: center;
    vertical-align: middle;	
}

.keyboard {
    width: 800px;
	height: 50px;
	margin: 1.5rem;
}
.modes {
    margin: 2rem;
}

.modes-item {
    margin: 0.5rem;
}

.modes-item:hover {
    color: var(--tertiary-knob-color);
    cursor: pointer;
}

.modes-item-active {
    color: var(--tertiary-knob-color);
    font-weight: bolder;
    margin: 0.5rem;
}


.chordProgressionBox2 {
	display: flex;
}

.chord-band {
	padding-top: 2rem;
	background-color: var(--secundary-bg-color);
	z-index: 1;
}

.chord-middle {
	background-color: var(--fourth-bg-color);
	font-size: 0.8rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	z-index: 1;
}

.chordProgressionBox-chord {
	width: 4rem;
	margin-right: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: var(--primary-bg-color); 
	border: .1rem var(--primary-bg-color) solid; 
	z-index: 1;
}

.chordProgressionBox-chord-active {
	width: 3rem;
	content: '';
	margin-right: .5rem;
	margin-top: 1.5rem; 
	border: .1rem var(--primary-button-color) solid; 
	z-index: 2; 
	position: absolute;
	margin-left: .5rem;
	padding-bottom: 6rem;
}


#svgchords {
	height: 120px;
	font-size: 0.3rem;
	width: 30rem;
}

#svgchords tspan {
	fill: var(--primary-knob-color);
}

.chordProgressionBoxTools {
    display: flex;     
    justify-content: space-between;
    align-items: center;
}

.chordProgressionBox-tool {	 
	background-color: var(--primary-bg-color);
	padding-top: .5rem;
    padding-bottom: .5rem;     
    width: 3rem;        
    text-align: center;
}

.chordProgressionBox-tool-active{
	background-color: var(--primary-button-color);
	padding-top: .5rem;
    padding-bottom: .5rem;     
    width: 3rem; 
	text-align: center;
}

.tool-icon{
	margin: auto;
	font-size: 1rem;
}

.chordProgressionBox-tool:hover {
	cursor: pointer;
	background-color: var(--primary-button-color)
}
.circleKeyboard {
    display: flex;
    flex-wrap: nowrap;
}

.chordprogressionDiv {
}

.harmonization {
    margin: 2rem;
    margin-left: 4rem;
}

