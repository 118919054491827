:root {
    --primary-bg-color: #454546;
    --secundary-bg-color: #2f2f2f;
    --tertiary-bg-color: #d7d6d6;
    --fourth-bg-color: #252525;
    --fifth-bg-color: #191919;

    --primary-button-color: #2882b7;
    --secundary-button-color: #ffcc00;
    --tertiary-button-color: #f98229;

    --primary-knob-color: #fff;
    --secundary-knob-color: #7bb22b;
    --tertiary-knob-color: #33a0e1;

    --alert-color: #ff0000;
}
