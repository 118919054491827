@import "../../../assets/styles/palette.css";
@import "../../../assets/styles/fonts.css";

.login {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.login-header {
	background-color: var(--fifth-bg-color);
	padding: 0.3rem;
	display: flex;
	height: 8rem;
	justify-content: center;
	width: 100%;
}

.login-header-logo {
	align-items: center;
	display: flex;
}

.login-header-logo-background {
	background-color: var(--secundary-button-color);
	font-weight: bolder;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}

.login-header-logo-font {
	color: var(--secundary-bg-color);
	font-weight: bolder;
	font-size: 2rem;
	font-family: av1;
	padding-top: 0.5rem;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}

.login-header-logo-label {
	background-color: var(--fifth-bg-color);
	color: var(--secundary-button-color);
	font-size: 1.6rem;
	font-family: av0;
	margin-left: 0.4rem;
	display: flex;
}

.login-header-logo-label > div{
	font-size: .6rem;
}

.login-form {
	background-color: var(--secundary-bg-color);
	border-radius: 0.5rem;
	border: 0.1px var(--tertiary-knob-color) solid;
	margin-top: 4rem;
	padding: 6rem;
	width: auto;
}

.login-service {
	padding: 1rem;
	display: flex;
	border: 0.1px var(--secundary-bg-color) solid;
}

.login-service:hover {
	border-radius: 0.5rem;
	border: 0.1px var(--tertiary-knob-color) solid;
	color: var(--tertiary-knob-color);
	cursor: pointer;
}

.login-service:hover div.login-service-logo {
	background-color: var(--tertiary-knob-color);
}

.login-service-logo {
	background-color: var(--primary-bg-color);
	color: var(--primary-knob-color);
	font-size: 1.5rem;
	padding: 1rem;
	text-align: center;
	width: 3.5rem;
}

.login-service-label {
	color: var(--primary-knob-color);
	font-size: 1rem;
	padding: 1rem;
}
