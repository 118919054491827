@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
 
  
#svg circle {
   
    transition: stroke-dashoffset 1s linear;
    stroke: #666;
    stroke-width: 1em;
    
  }
  #svg #bar {
    stroke: #FF9F1E; 
  }
  #cont {
    display: block;
    height: 200px;
    width: 200px;
    margin: 2em auto;
    box-shadow: 0 0 1em black;
    border-radius: 100%;
    position: relative;
  }
  #cont:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    box-shadow: inset 0 0 1em black;
    content: attr(data-pct)"%";
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 2em;
    text-shadow: 0 0 0.5em black;
  }
  