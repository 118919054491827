@import '../../../assets/styles/palette.css';

.intervals {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    width: 400px;
}

.intervals-id {
    display: flex;
    flex-wrap: nowrap;
}

.intervals-notes {
}

.intervals-item {
    box-sizing: border-box;
    flex-grow: 1;
    width: 3rem;
    padding: 0.6em;
    overflow: hidden;
    list-style: none;
    background-color: var(--secundary-bg-color);
    margin: 0.1rem;
}

.intervals-item:hover {
    background-color: var(--secundary-bg-color);
    color: var(--primary-bg-color);
    cursor: pointer;
}
