@import './palette.css';

body {
    background-color: var(--secundary-bg-color);
    font-family: av0;
    color: var(--tertiary-bg-color);
}

.content {
    display: flex;
}

h2.title {
    color: var(--secundary-button-color);
}

.form h2 {
    font-size: 1.5rem;
}

.form-body {
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    margin-top: 3rem;
}

.form-item {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    width: 100%;
}

.form-label {
    width: 30%;
}

.form-input {
    width: 100%;
}

select {
    appearance: none;
    border: 1px solid var(--fourth-bg-color);
    color: var(--tertiary-bg-color);
    width: inherit;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: var(--fourth-bg-color);
}

select:focus + .focus {
    background-color: var(--primary-bg-color);
    appearance: none;
}

input {
    appearance: none;
    border: 1px solid var(--fourth-bg-color);
    color: var(--tertiary-bg-color);
    width: inherit;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: var(--fourth-bg-color);
    margin-right: 4rem;
}

table.table {
    width: 100%;
}

.table th,
.table td {
    text-align: left;
    padding: 0.25em;
}

.table tr {
    border-bottom: 1px solid #ddd;
}

.form-select-icon {
    width: 2rem;
    background-color: var(--fourth-bg-color);
    border-radius: 0.2rem 0 0.2rem 0;
    text-align: center;
    margin: auto;
    position: relative;
    right: 2rem;
}
