@import "../../../assets/styles/palette.css";

.chordProgressionBox2 {
	display: flex;
}

.chord-band {
	padding-top: 2rem;
	background-color: var(--secundary-bg-color);
	z-index: 1;
}

.chord-middle {
	background-color: var(--fourth-bg-color);
	font-size: 0.8rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	z-index: 1;
}

.chordProgressionBox-chord {
	width: 4rem;
	margin-right: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: var(--primary-bg-color); 
	border: .1rem var(--primary-bg-color) solid; 
	z-index: 1;
}

.chordProgressionBox-chord-active {
	width: 3rem;
	content: '';
	margin-right: .5rem;
	margin-top: 1.5rem; 
	border: .1rem var(--primary-button-color) solid; 
	z-index: 2; 
	position: absolute;
	margin-left: .5rem;
	padding-bottom: 6rem;
}


#svgchords {
	height: 120px;
	font-size: 0.3rem;
	width: 30rem;
}

#svgchords tspan {
	fill: var(--primary-knob-color);
}
