@import '../../../assets/styles/palette.css';

.header {
    background-color: var(--fourth-bg-color);
    padding: 0.7rem;
    display: flex;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-background {
    background-color: var(--secundary-button-color);
    font-weight: bolder;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.logo-font {
    color: var(--secundary-bg-color);
    font-weight: bolder;
    font-size: 1.2rem;
    font-family: av1;
    padding-top: 0.1rem;
}

.logo-label {
    background-color: var(--fourth-bg-color);
    color: var(--secundary-button-color);
    margin-left: 0.4rem;
}

.logo-sign-out {
    font-size: 1.5rem;
    cursor: pointer;
}
.nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.nav-item {
    color: var(--primary-knob-color);
    padding-right: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.nav-item-active {
    color: var(--secundary-button-color);
    padding-right: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.nav-item:hover {
    color: var(--secundary-button-color);
    cursor: pointer;
}

.userImage {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.nav-left {
    display: flex;
    align-items: center;
}
