@import "../../../assets/styles/palette.css";

.notes {
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
	vertical-align: middle;
}

.note {
	background-color: var(--primary-bg-color);
    color: var(--tertiary-bg-color);
	border-radius: .5rem;
    height: 2.5rem;
    margin: 0.1rem;
    width: 2.5rem;
}

.active{
    background-color: var(--primary-button-color);
    color: var(--primary-knob-color);
}

.note:hover{
    background-color: var(--primary-button-color);
    color: var(--primary-knob-color);
    cursor: pointer;
}

.note > p {
    font-size: .8rem;
    font-weight: bolder;
	text-align: center;
    vertical-align: middle;	
}
