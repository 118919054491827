@import '../../../../assets/styles/palette.css';

.circleKeyboard {
    display: flex;
    flex-wrap: nowrap;
}

.chordprogressionDiv {
}

.harmonization {
    margin: 2rem;
    margin-left: 4rem;
}
