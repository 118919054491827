@import "../../../assets/styles/palette.css"; 
 

.chordProgressionBoxTools {
    display: flex;     
    justify-content: space-between;
    align-items: center;
}

.chordProgressionBox-tool {	 
	background-color: var(--primary-bg-color);
	padding-top: .5rem;
    padding-bottom: .5rem;     
    width: 3rem;        
    text-align: center;
}

.chordProgressionBox-tool-active{
	background-color: var(--primary-button-color);
	padding-top: .5rem;
    padding-bottom: .5rem;     
    width: 3rem; 
	text-align: center;
}

.tool-icon{
	margin: auto;
	font-size: 1rem;
}

.chordProgressionBox-tool:hover {
	cursor: pointer;
	background-color: var(--primary-button-color)
}