@import '../../../assets/styles/palette.css';

.modes {
    margin: 2rem;
}

.modes-item {
    margin: 0.5rem;
}

.modes-item:hover {
    color: var(--tertiary-knob-color);
    cursor: pointer;
}

.modes-item-active {
    color: var(--tertiary-knob-color);
    font-weight: bolder;
    margin: 0.5rem;
}
